var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail-container"},[_c('div',{staticClass:"good-selection-box"},[_c('span',{staticStyle:{"margin-right":"20px"}},[_vm._v("商品信息：")]),_c('div',{staticClass:"good-img"},[_c('img',{staticStyle:{"width":"48px","height":"48px","border-radius":"8px"},attrs:{"src":_vm.$route.query.src,"alt":""}})]),_c('div',{staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$route.query.name))])]),_c('div',{staticClass:"card-box"},[_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"card-title"},[_vm._v("引流漏斗")]),_c('div',{staticClass:"card-list"},[_c('div',{staticClass:"card-line"},[_vm._m(0),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.flowBase.visit))])]),_c('div',{staticClass:"card-line"},[_vm._m(1),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.flowBase.deal))])]),_c('div',{staticClass:"card-line"},[_vm._m(2),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.flowBase.click))])])])]),_c('div',{staticClass:"card-item"},[_c('div',{staticClass:"card-title"},[_vm._v("成交转化")]),_c('div',{staticClass:"card-list"},[_c('div',{staticClass:"card-line"},[_vm._m(3),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.dealBase.money))])]),_c('div',{staticClass:"card-line"},[_vm._m(4),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.dealBase.order))])]),_c('div',{staticClass:"card-line"},[_vm._m(5),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.dealBase.conversion)+"%")])])])])]),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_vm._v(" 浏览量 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_vm._v(" 成交件数指数 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_vm._v(" 点击指数 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_vm._v(" 成交金额指数 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_vm._v(" 成交单量指数 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('i',{staticClass:"iconfont"},[_vm._v("")]),_vm._v(" 成交转化率 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-box"},[_c('div',{staticClass:"data-title"},[_vm._v("引流漏斗")]),_c('div',{staticStyle:{"height":"400px"},attrs:{"id":"dataEcharts"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-box"},[_c('div',{staticClass:"data-title"},[_vm._v("成交转化")]),_c('div',{staticStyle:{"height":"400px"},attrs:{"id":"conversionEcharts"}})])
}]

export { render, staticRenderFns }